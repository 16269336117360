import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CartContext } from './Context';
import Moving from './MovingBanner';
import { CONSTURL } from './CONSTURL.js'; // Import the CONSTURL constant
const baseUrl = CONSTURL; // Use the imported CONSTURL
function Cart() {
    const { cartData, setCartData } = useContext(CartContext);
    const [flavourData, setFlavourData] = useState({});
    const [sizeData, setSizeData] = useState({});
    const [colorData, setColorData] = useState({});

    useEffect(() => {
        const fetchProductDetails = async () => {
            try {
                const productResponse = await fetch(baseUrl+'Products/');
                const productData = await productResponse.json();
                const products = productData.results;

                // Create a mapping of product id to flavour
                const flavourMap = {};
                products.forEach(product => {
                    flavourMap[product.id] = product.flavour.split(','); // Split flavour string into an array
                });

                setFlavourData(flavourMap);

            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        const fetchProductWearDetails = async () => {
            try {
                const response = await fetch(baseUrl+'product_wear/');
                const data = await response.json();
                
                // Create a mapping of product id to size and color
                const sizeMap = {};
                const colorMap = {};

                data.forEach(item => {
                    sizeMap[item.id] = item.size; // Map product id to size
                    colorMap[item.id] = item.color; // Map product id to color
                });

                setSizeData(sizeMap);
                setColorData(colorMap);

            } catch (error) {
                console.error('Error fetching product wear details:', error);
            }
        };

        fetchProductDetails();
        fetchProductWearDetails();
    }, []);

    useEffect(() => {
        if (cartData) {
            const updatedCartData = cartData.map(item => ({
                ...item,
                qty: item.qty || 1 // Default quantity to 1 if not provided
            }));
            setCartData(updatedCartData);
        }
    }, [cartData, setCartData]);

    const cartRemoveButtonHandler = (product_id) => {
        const updatedCartData = cartData.filter(cart => cart.product.id !== product_id);
        localStorage.setItem('cartData', JSON.stringify(updatedCartData));
        setCartData(updatedCartData);
    };

    const incrementQuantity = (index) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return { ...item, qty: item.qty + 1 };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const decrementQuantity = (index) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index && item.qty > 1) {
                return { ...item, qty: item.qty - 1 };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleFlavourChange = (index, selectedFlavour) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    selectedFlavour // Store selected flavour
                };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleSizeChange = (index, selectedSize) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    selectedSize
                };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    const handleColorChange = (index, selectedColor) => {
        const newCartData = cartData.map((item, idx) => {
            if (idx === index) {
                return {
                    ...item,
                    selectedColor
                };
            }
            return item;
        });
        setCartData(newCartData);
        localStorage.setItem('cartData', JSON.stringify(newCartData));
    };

    return (
        <div style={{ fontFamily: 'Roboto', marginTop: '220px', marginLeft: 'auto', marginRight: 'auto', width: '80%' }}>
            <section style={{ maxWidth: '1200px', margin: 'auto' }}>
                <Moving />
                <h3 style={{ textAlign: 'center', color: '#333', fontSize: '40px', fontFamily: 'Oswald' }}>
                    Panier ({cartData ? cartData.length : 0})
                </h3>
                <div style={{ marginBottom: '50px' }}></div>
                {cartData && cartData.length !== 0 ? (
                    <>
                        <div style={{ marginTop: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 1px 8px rgba(0,0,0,0.1)', padding: '20px' }}>
                            {cartData.map((item, index) => {
                                const individualPrice = (parseFloat(item.product.price) * item.qty).toFixed(2);
                                return (
                                    <div key={index} style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderBottom: '1px solid #eee',
                                        flexWrap: 'wrap' // Ensure wrapping for smaller screens
                                    }}>
                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                            <img src={item.product.image} alt={item.product.title} style={{
                                                width: '150px',
                                                height: 'auto',
                                                marginRight: '10px',
                                                borderRadius: '4px',
                                                maxWidth: '100%' // Ensure image doesn't exceed container width on mobile
                                            }} />
                                            <div style={{ marginTop: '10px' }}>
                                                <h4 style={{ margin: '0', color: 'black', fontSize: '27px' }}>{item.product.title}</h4>
                                                <p style={{ margin: '0', color: 'red', fontSize: '24px' }}>{individualPrice} DT</p>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {/* Show flavour dropdown only if the product has flavours */}
                                            {flavourData[item.product.id] ? (
                                                <select
                                                    value={item.selectedFlavour || ''}
                                                    onChange={(e) => handleFlavourChange(index, e.target.value)}
                                                    style={{ marginRight: '10px', padding: '5px', fontFamily: 'Roboto', fontWeight: 'bold', border: '2px solid black' }}
                                                >
                                                    <option value="" disabled>Select Flavour</option>
                                                    {flavourData[item.product.id].map((flavour, idx) => (
                                                        <option key={idx} value={flavour}>{flavour}</option>
                                                    ))}
                                                </select>
                                            ) : (
                                                <>
                                                    {/* Show size dropdown if no flavours */}
                                                    <select
                                                        value={item.selectedSize || ''}
                                                        onChange={(e) => handleSizeChange(index, e.target.value)}
                                                        style={{ marginRight: '10px', padding: '5px', fontFamily: 'Roboto', fontWeight: 'bold', border: '2px solid black' }}
                                                    >
                                                        <option value="" disabled>Select Size</option>
                                                        {sizeData[item.product.id] && Array.isArray(sizeData[item.product.id]) ? (
                                                            sizeData[item.product.id].map((size, sizeIndex) => (
                                                                <option key={sizeIndex} value={size}>
                                                                    {size}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="">No sizes available</option>
                                                        )}
                                                    </select>
    
                                                    {/* Show color dropdown if no flavours */}
                                                    <select
                                                        value={item.selectedColor || ''}
                                                        onChange={(e) => handleColorChange(index, e.target.value)}
                                                        style={{ marginRight: '10px', padding: '5px', fontFamily: 'Roboto', fontWeight: 'bold', border: '2px solid black' }}
                                                    >
                                                        <option value="" disabled>Select Color</option>
                                                        {colorData[item.product.id] && Array.isArray(colorData[item.product.id]) ? (
                                                            colorData[item.product.id].map((color, colorIndex) => (
                                                                <option key={colorIndex} value={color}>
                                                                    {color}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="">No colors available</option>
                                                        )}
                                                    </select>
                                                </>
                                            )}
    
                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                                <button onClick={() => decrementQuantity(index)} style={{ border: '2px solid black', backgroundColor: 'white', color: '#2b9923', padding: '5px 10px', borderRadius: '4px', margin: '0 5px' }}>-</button>
                                                <span style={{ fontWeight: 'bold', margin: '0 10px', fontSize: '18px' }}>{item.qty}</span>
                                                <button onClick={() => incrementQuantity(index)} style={{ border: '2px solid black', backgroundColor: 'white', color: '#2b9923', padding: '5px 10px', borderRadius: '4px', margin: '0 5px' }}>+</button>
                                            </div>
                                            <div>
                                                <button onClick={() => cartRemoveButtonHandler(item.product.id)} style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer' }}>
                                                    <i className="fa fa-trash" style={{ fontSize: '25px' }}></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
    
                        <div style={{
                            marginTop: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '10px',
                            background: '#e9ecef',
                            borderRadius: '8px',
                            flexWrap: 'wrap'
                        }}>
                            <div style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>
                                <p style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>Article :</p>
                                <p style={{ margin: '0', fontSize: '20px', color: 'black' }}>{cartData.length}</p>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <p style={{ fontWeight: 'bold', margin: '0', fontSize: '20px' }}>Total TTC :</p>
                                <p style={{ margin: '0', fontSize: '20px', color: 'black' }}>{cartData.reduce((total, item) => total + parseFloat(item.product.price) * item.qty, 0).toFixed(2)} DT</p>
                            </div>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '15px',
                                marginTop: '10px'
                            }}>
                                <Link to="/Stores/1" style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: 'bolder',
                                    fontSize: '20px',
                                    padding: '15px 15px',
                                    background: '#000080',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    borderRadius: '5px',
                                    textAlign: 'center'
                                }}>
                                    Acheter plus
                                </Link>
                                <Link to="/OrderValidation" style={{
                                    fontFamily: 'Roboto',
                                    fontWeight: 'bolder',
                                    fontSize: '20px',
                                    padding: '15px 20px',
                                    background: '#2ecc71',
                                    color: '#fff',
                                    textDecoration: 'none',
                                    borderRadius: '5px',
                                    textAlign: 'center'
                                }}>
                                    Commander
                                </Link>
                            </div>
                        </div>
    
                    </>
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Link to="/Stores/1" style={{
                            padding: '15px 25px',
                            background: '#000080',
                            color: '#fff',
                            textDecoration: 'none',
                            borderRadius: '5px',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            display: 'inline-block',
                            textAlign: 'center'
                        }}>Continuer vos achats</Link>
                    </div>
                )}
            </section>
        </div>
    );
    
    
}

export default Cart;